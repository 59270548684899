import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import ServicesList from '../../components/services-list'

const CategoryPage = ({ data }) => {
  const {
    frontmatter: { title, description, caracteristics, slug, parent, seoTitle },
  } = data.markdownRemark

  return (
    <Layout
      title={title}
      description={description}
      seoTitle={seoTitle}
      breadcrumpParent={parent}
    >
      <ServicesList services={caracteristics} urlPrefix={slug} />
    </Layout>
  )
}

export default CategoryPage

CategoryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query CategoryPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        slug
        description
        seoTitle

        parent {
          title
          url
        }

        caracteristics {
          iconName
          title
          description

          cta {
            text
            url
          }
        }
      }
    }
  }
`
