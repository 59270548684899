import React from 'react'
import { Link } from 'gatsby'

import FeatherIcon from '../../components/feather-icon'

const ServiceBlockContent = ({ title, description, iconName }) => (
  <div className="service">
    <FeatherIcon name={iconName} className={title ? null : 'text-center'} />
    <div className="content">
      {title && <h3 className="title">{title}</h3>}
      <p>{description}</p>
    </div>
  </div>
)

const ServiceBlockLink = ({ url, children }) => {
  if (url) {
    return (
      <Link className="btn-transparent rn-btn-dark" to={url}>
        {children}
      </Link>
    )
  }

  return null
}

const ServiceBlock = ({
  url,
  text,
  lgSize = '6',
  textAlign = 'left',
  ...rest
}) => {
  return (
    <div className={`col-lg-${lgSize} col-md-6 col-sm-6 col-12`}>
      <div className={`single-service service__style--1 text-${textAlign}`}>
        <ServiceBlockContent {...rest} />
        <ServiceBlockLink url={url}>{text}</ServiceBlockLink>
      </div>
    </div>
  )
}

export default ServiceBlock
