import React from 'react'
import ServiceBlock from '../service-block'

import './main.scss'

const ServicesList = ({ services, urlPrefix, bgColor, ...serviceOptions }) => {
  return (
    <div
      className={`rn-service-area pt--100 pb--100${
        bgColor ? ' bg_color--' + bgColor : ''
      }`}
    >
      <div className="container services-list--container">
        <div className="row d-flex justify-content-center">
          {services.map(({ title, description, iconName, cta }, index) => (
            <ServiceBlock
              key={`service_${index}_${title}`}
              title={title}
              description={description}
              iconName={iconName}
              url={urlPrefix && cta ? urlPrefix + cta.url : cta?.url}
              text={cta?.text}
              {...serviceOptions}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ServicesList
